import { withQuery } from 'ufo'
import type { LocationQuery } from '#vue-router'

export default function useURLs() {
  const route = useRoute()

  const canonical = computed(() => useConfdata().seoLinks?.canonical.href)

  function isCanonical(path: string): boolean {
    return !!canonical.value && path === getUrlFullPath(canonical.value)
  }

  // Builds a url according to the given path and query. If the path is known in the router use the guestWorldUrl, otherwise use the discoveryUrl
  function buildUrl(path: string, query: LocationQuery): BuildURL {
    if (isKnownPath(path) && path !== '/') {
      return { path: buildGuestWorldUrl(path, query), external: false }
    }

    return { path: buildDiscoveryUrl(path, query), external: true }
  }

  function isKnownPath(path: string): boolean {
    return useRoute().matched.some((route) => route.path === path)
  }

  function buildDiscoveryUrl(path: string, query: LocationQuery): string {
    const url =
      useRequestURL().origin + query.language
        ? useConfdata().languagePrefix
        : `${path}`

    return withQuery(url, query)
  }

  function buildGuestWorldUrl(path: string, params?: object): string {
    return withQuery(path, {
      language: useConfdata().languagePrefix
        ? useConfdata().language
        : undefined,
      ...(params ?? {}),
    })
  }

  function buildLoginUrl(): string {
    return buildGuestWorldUrl('/login', {
      returnTo: addUrlParams(useRequestURL(), { signedIn: true }).toString(),
      ...useParams().persistent,
    })
  }

  function buildProfileUrl(): string {
    return buildGuestWorldUrl('/myaccount/profile', useRoute().query)
  }

  function buildWishlistsUrl(): string {
    return buildGuestWorldUrl('/myaccount/wishlists', useRoute().query)
  }

  function buildWishlistUrl(wishlistId: string): string {
    return buildGuestWorldUrl(
      `/myaccount/wishlists/${wishlistId}`,
      useRoute().query,
    )
  }

  function buildBookingsUrl(): string {
    return buildGuestWorldUrl('/myaccount/bookings', useRoute().query)
  }

  async function cleanQuery(keys: string[], ms = 0) {
    await new Promise((resolve) => setTimeout(resolve, ms))

    const query = { ...route.query }
    keys.forEach((key) => {
      delete query[key]
    })
    const router = useRouter()
    await router.replace({ path: route.path, query })
  }

  return {
    canonical,
    buildUrl,
    cleanQuery,
    isCanonical,
    buildLoginUrl,
    buildProfileUrl,
    buildBookingsUrl,
    buildWishlistUrl,
    buildWishlistsUrl,
  }
}
